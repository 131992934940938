:root {
    --qty-background: #{$white};
    --qty-border-radius: 7px;
    --qty-button-height: 40px;
    --qty-button-width: 42px;
    --qty-button-background: #{$grey-light};
    --qty-button-background-disabled: #{$grey-light};
    --qty-button-border-disabled: #{$grey-light};
    --qty-button-border-color: #{$grey-light};
    --qty-button-border-radius: 7px;
    --qty-input-color: #{$blue50};
}

@mixin qty-input {
    background-color: var(--qty-background);
    border-radius: var(--qty-border-radius);
    margin-right: 24px;
    @include mobile {
        margin-right: 12px;
    }
    @include tablet {
        margin-right: 24px;
    }
    @include desktop {
        margin-right: 24px;
    }
    input[type='number'] {
        pointer-events: initial;
        width: 56px;
        height: var(--qty-button-height);
        padding: 0;
        color: var(--qty-input-color);
        font-family: $font-secondary;
        margin: 0 2px;
        font-size: 15px;
        line-height: 150%;
        font-weight: 400;
        background-color: var(--qty-button-background);
        border-radius: 0;
        &:focus {
            box-shadow: none;
        }

        ~ button {
            width: var(--qty-button-width);
            height: var(--qty-button-height);
            background-color: var(--qty-button-background);
            border-radius: var(--qty-button-border-radius);
            border-color: var(--qty-button-border-color);

            @include mobile {
                --qty-button-width: 40px;
            }
            &:first-of-type {
                border-radius: 0 var(--qty-button-border-radius) var(--qty-button-border-radius) 0;
            }

            &:last-of-type {
                border-radius: var(--qty-button-border-radius) 0 0 var(--qty-button-border-radius);
            }

            &:disabled {
                opacity: 1;
                background-color: var(--qty-button-background-disabled);
                border-color: var(--qty-button-border-disabled);

                svg {
                    fill: $grey4;
                }
            }

            svg {
                width: 20px;
                fill: var(--qty-input-color);
            }
        }
    }
}
