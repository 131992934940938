@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

[dir='ltr'] .CartItem {
    border-bottom: 1px solid $grey5;

    &-Options,
    &-Quantity {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.2px;
        color: $grey-dark;
        padding: 0;
        &:not(:empty) {
            margin: 16px 0 0;
        }
    }

    &-Quantity {
        &:not(:empty) {
            margin: 6px 0 0;
            @include mobileAndTablet {
                margin: 0;
            }
        }
    }

    &-Delete {
        @include desktop {
            margin: 0 0 0 auto;
        }
    }
    &-Wrapper {
        padding: 24px var(--content-padding);

        &_isMobileLayout {
            grid-gap: 10px;

            @include from-tablet {
                grid-gap: 24px;
            }
        }

        @include desktop {
            grid-template-columns: minmax(100px, 4fr) 2fr 1fr 80px;
            grid-gap: 0;
        }
    }
    &-Heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        color: $default-primary-base-color;
        margin: 0;
    }
    &-Options {
        &:empty {
            padding: 0;
        }
    }
    &-ProductActions {
        &_isMobileLayout {
            display: flex;
            align-items: center;
            margin-top: 16px;
        }
    }
    &-Price {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        text-align: right;
        letter-spacing: -0.2px;
        color: $black;
    }
    &-Qty {
        @include qty-input;
    }
}
