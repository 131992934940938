@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

[dir='ltr'] .CartCoupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 32px;
    margin: 18px 0 7px;

    @include mobile {
        padding: 0 17px;
        margin: 4px 0 15px;
    }

    @include tablet {
        padding: 0 17px;
        margin: 18px 0 2px;
    }

    &-Input {
        width: 100%;
        margin: 0;
        height: var(--button-height);
    }

    &-Button {
        margin: 0;
        width: 116px;
        position: absolute;
        top: 0;
        right: 31px;
        letter-spacing: 0.07em;
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;

        @include mobileAndTablet {
            padding: 0 17px;
            right: 16px;
        }
    }

    &-Message {
        width: 100%;
        + .CartCoupon-Button {
            position: static;
            width: 100%;
        }
    }
}
